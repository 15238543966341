const useAuthPasswordRemindDto = async (payload: PasswordRemindPayload) => {
  const response = await useFetchData<FetchData>("/auth/remind", {
    body: payload,
    method: "POST",
  });

  return {
    ...response,
  };
};

export { useAuthPasswordRemindDto };
