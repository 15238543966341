const AUTH_PASSWORD_REMIND_SCHEMA = schema.object({
  email: schema
    .string({
      required_error: localeRuValidate.required,
    })
    .email({
      message: localeRuValidate.email,
    }),
});

type AuthPasswordRemindSchemaInfer = SchemaInfer<typeof AUTH_PASSWORD_REMIND_SCHEMA>;

type AuthPasswordRemindFields = FormFields<AuthPasswordRemindSchemaInfer>;

type AuthPasswordRemindForm = PasswordRemindPayload;

export { AUTH_PASSWORD_REMIND_SCHEMA };
export type { AuthPasswordRemindFields, AuthPasswordRemindForm };
