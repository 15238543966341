const useAuthPasswordRemindForm = () => {
  const { openNotification } = useNotificationStore();

  const { defineField, errors, handleSubmit, resetForm, setErrors } = useForm<AuthPasswordRemindForm>({
    validationSchema: toTypedSchema(AUTH_PASSWORD_REMIND_SCHEMA),
  });

  const fields: AuthPasswordRemindFields = {
    email: defineField("email"),
  };

  const { closeModal } = useModalStore();
  const loading = ref(false);

  const submit = handleSubmit(async (formData) => {
    loading.value = true;

    const { body, success } = await useAuthPasswordRemindDto(formData);

    loading.value = false;

    if (success) {
      closeModal();
      resetForm();
      openNotification("Письмо с ссылкой на восстановление пароля отправлено");
    }

    if (!success) {
      setErrorsFields.rest(setErrors, body);
    }
  });

  return {
    errors,
    fields,
    handleSubmit: submit,
    loading,
  };
};

export { useAuthPasswordRemindForm };
